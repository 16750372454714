<template>
    <div>
        <div class="fl-x-cc">
            <h4 class="text-seventh">Diseases</h4>
        </div>
        <div>
            <validated-input placeholder="search" type="text" :border-radius="1" v-model="search"></validated-input>
        </div>
        <template v-if="data!=''">
            <div class="font-proxima-bold text-center text-dark b-1 mb-2" v-for="(item,index) in data" :key="index">
                <p class="pt-3" @click="$router.push('/disease-list/'+item.id+'/')"> {{ item.disease }} </p>
            </div>
        </template>
        <!--       <div  v-else>

               </div>-->
        <div class="fl-x fl-j-c " v-else>
                <disease-modal v-if="startSearch" class=""></disease-modal>
            <div v-else>
                <found-item v-if="loading"></found-item>

                <div class="fl-x" v-else>
                    <loading-animation></loading-animation>
                    <p>Loading...</p>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';
import DiseaseModal from '@/views/user-home/disease/DiseaseModal';

export default {
    name: 'DiseaseAndTreatments',
    components: { DiseaseModal },
    watch: {
        search (newVal, oldVal) {
            console.log(newVal, oldVal);
            this.searchItem();
        }
    },
    data () {
        return {
            loading: false,
            startSearch: false,
            data: [],
            listURL: urls.userHome.disease.list,
            search: '',

            cat_id: this.$route.params.id

        };
    },
    mounted () {
        this.fetchItem();
    },
    methods: {
        async searchItem () {
            this.startSearch = true;
            console.log('Searching...', this.startSearch);
            if (this.search === undefined) {
                this.search = '';
            }
            try {
                this.loading = true;
                const response = await axios.form(this.listURL, { search: this.search, cat_id: this.cat_id });
                const result = response.data.data;
                this.data = result;
                console.log('result', result);
                if (result.success === true) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async fetchItem () {
            console.log('Fetching...');
            try {
                const response = await axios.form(this.listURL, { cat_id: this.$route.params.id });
                const result = response.data.data;
                this.data = result;
                console.log('result', result);
                if (result.success === true) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
